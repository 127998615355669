import React, { useState, useEffect } from 'react'
import { Row, Col, Typography, Avatar, Image, Space } from 'antd';
import { useLocation } from 'react-router-dom';
import './HeaderView.less'
const { Title, Text } = Typography;

const header_title = { margin:0 };
const user_details = { marginRight:10 };
const user_name = { margin:0, fontWeight:600, fontSize:17 };
const user_position = { margin:0, fontWeight:400, fontSize:14, color:'gray' };
const avatar_style = { width: 45 }
const default_avatar = { width: 40 }

const HeaderView = (props: any) => {
    const [user, setUser]:any = useState({})
    const [path, setPath]:any = useState('employees')
    const location = useLocation();

    const fallback_url = process.env.REACT_APP_FALLBACK_URL;

    const getUser = async () => {
        setUser({
            ...user, 
            imageUrl: JSON.parse(localStorage.getItem('user') as any).employee_id.image.url, 
            position: JSON.parse(localStorage.getItem('user') as any).employee_id.position,
            name: JSON.parse(localStorage.getItem('user') as any).first_name + " " + JSON.parse(localStorage.getItem('user') as any).last_name
        });
        let tmp_path = location.pathname.slice(1)
        let [path_name] = tmp_path.split('/');
        setPath(path_name[0].toUpperCase() + path_name.slice(1))
    }

    useEffect(() => {
        const initialize = () => {
            getUser();
        };
        initialize();
    }, [location]);

    return (
        <>
            <Row align='middle' className='header-class'>
                <Col span={8}>
                    <Row>
                        <Title level={3} style={header_title}>{path}</Title>
                    </Row>
                </Col>
                <Col span={16}>
                    <Row justify='end' align='middle'>
                        <Space direction={'vertical'} size={0} style={user_details}>
                            <Row justify='end'>
                                <Text style={user_name}>{user.name}</Text>
                            </Row>
                            <Row justify='end'>
                                <Text style={user_position}>{user.position}</Text>
                            </Row>
                        </Space>
                        <Row>
                            <Avatar 
                                size={45} 
                                src={<Image 
                                    src={user.imageUrl} 
                                    style={user.imageUrl ? avatar_style : default_avatar}
                                    preview={false} 
                                    fallback={fallback_url}
                                />}
                                className='header-avatar'
                            />
                        </Row>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default HeaderView