import APIService from "./APIService";

class AttendanceService extends APIService {
    search = async (search: string = '', page: any = 1, limit: any = 10) => {
        const result = await this.get('users/search?generic=' + search + '&page=' + page + '&limit=' + limit);
        return result ? result.data : null;
    }

    getUser = async ({ _id }: any) => {
        const result = await this.get(`users/${_id}`);

        return result ? result.data : null;
    }

    addUser = async (formData: any) => {
        const result = await this.post(formData, 'users/');
        return result ? result.data : null;
    }

    editUser = async (id: string, formData: any) => {
        const result = await this.put(formData, 'users/' + id);
        return result ? result.data : null;
    }

    getDailyAttendance = async ({ user_id }: any) => {
        const result = await this.get(`attendance/daily/${user_id}`);

        return result ? result.data : null;
    }

    getUserAttendance = async (filter: any, user_id: any, limit: any, page: any) => {
        const result = await this.get(`attendance/list/${user_id}` + '?filter=' + filter + `&limit=${limit}&page=${page}`);

        return result ? result.data : null;
    }

    timein = async ({ user_id }: any) => {
        const result = await this.post({ user_id }, 'attendance/timein');

        return result ? result.data : null;
    }

    timeout = async ({ _id, undertime_reason }: any) => {
        const result = await this.put({ undertime_reason }, `attendance/timeout/${_id}`);

        return result ? result.data : null;
    }

    getAttendance = async (user_id: any, page: any, limit: any) => {
        const result = await this.get(`attendance/daily/${user_id}` + '?page=' + page + '&limit=' + limit);

        return result ? result.data : null;
    }

    filter = async (filter: any, user_id: any, page: any, limit: any) => {
        const result = await this.get(`attendance/filter/${user_id}` + '?filter=' + filter + '&page=' + page + '&limit=' + limit);

        return result ? result.data : null;
    }

    autoTimeout = async () => {
        const result = await this.put({}, 'attendance/auto-timeout');

        return result ? result.data : null;
    }

    checkDailyAttendance = async ({ employee_id, current_date }: any) => {
        const result = await this.post({ current_date }, `attendance/check/daily/${employee_id}`);

        return result ? result.data : null;
    }

    checkAttendanceByDate = async ({ employee_id, current_date }: any) => {
        const result = await this.post({ current_date }, `attendance/check/by-date/${employee_id}`);

        return result ? result.data : null;
    }

    checkTotalHours = async (_id: any) => {
        const result = await this.get(`attendance/check-total-hours/${_id}`);

        return result ? result.data : null;
    }

}

export default AttendanceService