import React, { Component, lazy } from "react";
import "./App.css";
import axios from 'axios'
import Loader from "./components/Loader";
import lazyComponentLoader from './hoc/LazyLoader';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ProtectedRoute from './hoc/ProtectedRoute';
import { isArray } from "util";
import DialogUtils from "./utils/DialogUtils";
import LayoutView from "./views/layout/LayoutView";
import AuthService from "./services/api/AuthService";
import PublicRoute from "./hoc/PublicRoute";
import AttendanceService from './services/api/AttendanceService';

interface IAppState {
  user: any
  isLoading: Boolean
  isLoggedIn: Boolean
}

const authService = new AuthService();
const attendanceService = new AttendanceService();

const Login:any = lazyComponentLoader(() => import('./views/auth/login/LoginView'));

const CompanyDetailLayoutView:any = lazyComponentLoader(() => import('./views/auth/auth-layout/CompanyDetailLayoutView'));

const Dashboard:any = lazyComponentLoader(() => import('./views/dashboard/DashboardView'));

const ProfileView:any = lazyComponentLoader(() => import('./views/profile/ProfileView'));

const LeavesView:any = lazyComponentLoader(() => import('./views/leaves/LeavesView'));

const OvertimeView:any = lazyComponentLoader(() => import('./views/overtime/OvertimeView'));

const LoanView:any = lazyComponentLoader(() => import('./views/loan/LoanView'));

const AttendanceView:any = lazyComponentLoader(() => import('./views/attendance/AttendanceView'));

class App extends Component<any, IAppState> {
  constructor(props:any) {
    super(props);
    //initialize state here
    this.state = {
        user: {},
        isLoading: false,
        isLoggedIn: false
    }

    axios.interceptors.response.use(
      function (response) {
        
        return response;
      },
      async (error) => {
        if (error.response) {
          this.showError(error.response.data.errors);
        } else {
          this.showError(error);
        }
        return Promise.reject(error);
      }
    );
  }

  componentDidMount() {
    const data:any = localStorage.getItem('user');
    this.setState({ 
      user: JSON.parse(data),
      isLoggedIn: localStorage.getItem('access_token') ? true : false
    });
  }

  showError(errors: any) {
    let content = null;
    if (isArray(errors)) {
      content = (
        <div>
          {errors.map((item: any, index: number) => {
            return (
              <div key={Math.random().toString(6)}>
                {item.message.includes('timeout') ||
                item.message.includes('access_token') ||
                item.message.includes('jwt expired') ||
                item.message.includes('authorization')
                  ? 'Your session has timed out.'
                  : item.message}
              </div>
            );
          })}
        </div>
      );
    } else {
      content = (
        <div>
          {errors.message.includes('Network Error')
            ? 'No network connection. Make sure that WI-FI or Cellular Mobile Data is turned on, then try again.'
            : errors.message.includes('jwt expired') 
            ? 'Your session has timed out.'
            : 'Your session has timed out. Please login again.'}
        </div>
      );
    }

    DialogUtils.error('Error', content, () => { this.onOk() });
  }

  onOk() {
    localStorage.clear();
    window.location.replace('/');
  }
  
  render() {
    return (
      <div>
        <Router>
            <Switch>
              <PublicRoute
                exact path='/'
                component={Login}
              />
              <Route
                exact path='/read-company-policy'
                component={CompanyDetailLayoutView}
              />
              <LayoutView>
                <ProtectedRoute
                  exact
                  path='/dashboard'
                  component={Dashboard}
                />
                <ProtectedRoute
                  exact 
                  path='/profile'
                  component={ProfileView}
                />
                <ProtectedRoute
                  exact 
                  path='/attendance'
                  component={AttendanceView}
                />
                <ProtectedRoute
                  exact
                  path='/leave'
                  component={LeavesView}
                />
                <ProtectedRoute
                  exact 
                  path='/overtime'
                  component={OvertimeView}
                />
                <ProtectedRoute
                  exact 
                  path='/loan'
                  component={LoanView}
                />
              </LayoutView>
            </Switch>
        </Router>
        
        {this.state.isLoading ? <Loader /> : null}
      </div>
    );  
  }
}

export default App;