/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Layout,
    Menu,
    MenuProps,
    Button,
    Space,
    Divider,
    Row,
    Image
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import {
    BusinessCenter,
    Dashboard,
    EventAvailable,
    WatchLater,
    Payment,
    ExitToApp
} from '@material-ui/icons';
import { UserOutlined } from '@ant-design/icons';
import ArkLogo from '../../../../assets/img/ark-digital.png'
import './SideMenuView.less';
const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    theme?: 'light' | 'dark',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        theme,
    } as MenuItem;
}

const ark_logo = { marginTop: 40, marginBottom: 20 }
const divider = { margin: 0 }
const logout_btn_icon = { marginRight: 10 }

const SideMenuView = () => {
    const location = useLocation();
    const history = useHistory();
    const [currentPath, setCurrentPath] = useState(location.pathname);
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const onClick: MenuProps['onClick'] = e => {
        setCurrentPath(e.key);
        history.push(e.key);
    };

    const items: MenuItem[] = [
        getItem('DASHBOARD', '/dashboard', <Dashboard className='menu-item-class' />),
        getItem('ATTENDANCE', '/attendance', <EventAvailable className='menu-item-class' />),
        getItem('LEAVE', '/leave', <BusinessCenter className='menu-item-class' />),
        getItem('OVERTIME', '/overtime', <WatchLater className='menu-item-class' />),
        user.employee_id && user.employee_id.status === 'Regularized' ? getItem('LOAN', '/loan', <Payment className='menu-item-class' />) : null,
        getItem('PROFILE', '/profile', <UserOutlined className='menu-item-class' />),
    ];

    const onLogout = () => {
        localStorage.clear();
        history.push('/');
    }

    return (
        <Sider
            width={250}
            theme='light'
            className='font-style'
        >
            <Space
                direction="vertical"
                className='dashboard-menu'
            >
                <Row justify='center' style={ark_logo}>
                    <Image
                        preview={false}
                        width={120}
                        src={ArkLogo}
                    />
                </Row>
                <Menu
                    onClick={onClick}
                    mode="inline"
                    selectedKeys={[currentPath]}
                    defaultSelectedKeys={[currentPath]}
                    items={items}
                    theme='dark'
                    className='sidebar'
                />
            </Space>
            <Divider style={divider} />
            <Space
                align='center'
                className='dashboard-logout'
            >
                <Button
                    type='link'
                    className='dashboard-logout-btn'
                    icon={
                        <ExitToApp style={logout_btn_icon} />
                    }
                    onClick={onLogout}
                >
                    LOGOUT
                </Button>
            </Space>
        </Sider>
    );
}

export default SideMenuView