import APIService from "./APIService";

class AuthService extends APIService {
  verifyResetPasswordToken = async (token: string) => {
    const result = await this.post(
      { token },
      "auth/verify-reset-password-token"
    );
    return result ? result.data : null;
  };

  login = async ({ email, password }: any) => {
    const result = await this.post({ email, password }, "auth/login");

    return result ? result.data : null;
  };

  googleLogin = async ({credential}:any) => {
    const result = await this.post(
      {credential}, 
      "auth/google-login"
    );

    return result ? result.data : null;
  }

  checkUser = async ({email}: any) => {
    const result = await this.get(`auth/check/${email}`);

    return result ? result.data : null;
  }

  addUser = async ({family_name, given_name, email, imageUrl}:any) => {
    const result = await this.post(
      {family_name, given_name, email, imageUrl}, 
      "auth/add-user"
    );

    return result ? result.data : null;
  }

  facebookLogin = async ({ accessToken, email, name, userID }: any) => {
    const result = await this.post(
      { name, email, userID, accessToken },
      "/auth/facebook-login"
    );

    return result ? result.data : null;
  };

  linkedInLogin = async ({ code, path }: any) => {
    // const result = await this.post({code, path}, '/auth/linked-in-login');
    const result = await this.post({ code, path }, "auth/linked-in-login");

    return result ? result.data : null;
  };

  logout = async (accessToken: string, refreshToken: string) => {
    const result = await this.post({ refreshToken, accessToken }, "auth/logout");
    return result ? result.data : null;
  }

  forgotPassword = async ({ email }: any) => {
    const result = await this.post({ email }, "auth/forgot-password");

    return result ? result.data : null;
  };

  resetPassword = async ({ token, new_password, confirm_password }: any) => {
    const result = await this.post(
      { token, new_password, confirm_password },
      "auth/reset-password"
    );

    return result ? result.data : null;
  };

  send2fa = async (contact: string) => {
    const result = await this.post({ contact }, "auth/send2fa");

    return result ? result.data : null;
  };

  verify2fa = async ({ code, contact_num, serviceSid }: any) => {
    const result = await this.post(
      { code, contact_num, serviceSid },
      "auth/verify2fa"
    );

    return result ? result.data : null;
  };

  // --- USER PROFILE ---
  changePassword = async (formData: any) => {
    const result = await this.put(formData, "profile/change-password");
    return result ? result.data : null;
  };

  getProfile = async () => {
    //  const result = await this.get('/profile');
    const result = await this.get("profile");
    return result ? result.data : null;
  };

  update2fa = async (is_enable: boolean, contact?: string) => {
    const result = await this.put({ is_enable, contact }, "profile/2fa");
    return result ? result.data : null;
  };

  updateContactNumber = async (newContactNumber: any) => {
    const result = await this.put(
      { newContactNumber },
      "profile/update-contact-number"
    );
    return result ? result.data : null;
  };

  editProfile = async (formData: any) => {
    const result = await this.put(formData, "profile");
    return result ? result.data : null;
  };

  editProfileImage = async (formData: any) => {
    const result = await this.put(formData, "profile/image");
    return result ? result.data : null;
  };

  uploadImg = async (id: string, file: any) => {
    const result = await this.post(file, "s3/upload?tmp=" + id);
    return result ? result.data : null;
  };

  uploadUserProfileImg = async (id: string, file: any) => {
    const result = await this.post(file, "s3/upload?tmp=" + id);
    return result ? result.data : null;
  };

  // --- END ---

  register = async ({ email, password }: any) => {
    const result = await this.post({ email, password }, "company");

    return result ? result.data : null;
  };

  companyDetail = async (
    id: string,
    {
      position,
      full_name,
      company,
      company_description,
      industry,
      contact_number,
      street_address,
      city,
      state,
      zip_code,
      country,
      geolocation,
      company_details,
      number_employee,
      interval,
      reduction,
      year,
      facilities,
      tmp,
      baseline_year,
    }: any
  ) => {
    const result = await this.put(
      {
        position,
        full_name,
        company,
        company_description,
        industry,
        contact_number,
        street_address,
        city,
        state,
        zip_code,
        country,
        geolocation,
        company_details,
        number_employee,
        interval,
        reduction,
        year,
        facilities,
        tmp,
        baseline_year,
      },
      "company/" + id
    );

    return result ? result.data : null;
  };

  companyReductionAndInterval = async (
    id: string,
    { interval, reduction, year }: any
  ) => {
    const result = await this.put(
      { interval, reduction, year },
      "company/" + id + "/reduction"
    );
    return result ? result.data : null;
  };

  companyFacilities = async (
    id: string,
    { facilities, baseline_year }: any
  ) => {
    const result = await this.put(
      { facilities, baseline_year },
      `company/facilities/${id}`
    );
    return result ? result.data : null;
  };

  accountIntegration = async ( company_id: string, { accounting, utility }: any ) => {
    const result = await this.post( { accounting, utility }, `company/${company_id}/account_integration`
    );
    return result ? result.data : null;
  };

  updateSuggestedSoftwareBill = async (id: string, formData: any) => {
    const result = await this.put(
      formData,
      `company/account_integration/upload/${id}`
    );
    return result ? result.data : null;
  };

  paymentDetail = async (
    id: string,
    { first_name, last_name, ccnumber, ccexp }: any
  ) => {
    const result = await this.put(
      { first_name, last_name, ccnumber, ccexp },
      "company/payment_details/" + id
    );

    return result ? result.data : null;
  };

  getPaymentDetails = async (id: string) => {
    const result = await this.get(`company/payment_details/${id}`);
    return result ? result.data : null;
  };

  skipPaymentDetails = async (id: string) => {
    const result = await this.get(`company/payment_details/${id}/skip`);
    return result ? result.data : null;
  };

  surveyOnboarding = async (id: string, answer: any) => {
    const result = await this.post(answer, "company/survey/onboarding/" + id);

    return result ? result.data : null;
  };

  createSurveyDraft = async (id: string, year: any): Promise<any> => {
    const result = await this.post({ id, year }, "surveys");
    return result ? result.data : null;
  };

  createSurveyDraftandBill = async (
    customer_id: string,
    survey_id: string,
    formData: any
  ) => {
    const result = await this.put(
      formData,
      `surveys/${survey_id}/upload/${customer_id}`
    );
    return result ? result.data : null;
  };

  uploadFacilityFiles = async (formData: any) => {
    const result = await this.put(formData, `facilities/upload`);
    return result ? result.data : null;
  };

  isLoggedIn = () => {
    return localStorage.getItem("access_token") ? true : false;
  };

  isOnBoarding = () => {
    return localStorage.getItem("isOnboarding");
  };

  isRollUpCustomer = () => {
    const data: any = localStorage.getItem("user");
    return JSON.parse(data);
  };

  test = async () => {
    const result = await this.get("test-api-call");

    return result ? result.data : null;
  };

  uploadFacilityResourceUsageBills = async (
    resource_usage_id: string,
    survey_id: string,
    formData: any
  ) => {
    const result = await this.put(
      formData,
      `surveys/${survey_id}/facility-resource/upload/${resource_usage_id}`
    );
    return result ? result.data : null;
  };

  updateBaselineYear = async (companyId: string, formData: any ) => {
    const result = await this.put( formData, `company/update-baseline-year/${companyId}`);
    return result ? result.data : null;
  };

  uploadHandler = async (file:any) => {
    const result = await this.post(file,`company/account_integration/upload?file=${file}`);
    return result ? result.data : null;
  };
  
}

export default AuthService;
