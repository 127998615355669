import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({component:Component, setPageHeader, setHeaderItemChange, headerItems, ...rest}:any) => {
  
    // TODO: temporary - delete this code once cookie is implemented
    // if (!localStorage.getItem('refresh_token')) {
    //   localStorage.clear();
    //   <Redirect to='/login'/>
    // }
    // END: temporary 

    return (
        <Route
            {...rest}
            render={props => 
                {
                    if(localStorage.getItem('access_token')) {
                        return <Component {...props} setPageHeader={setPageHeader} setHeaderItemChange={setHeaderItemChange} headerItems={headerItems}/>
                    }
                    else{
                        return <Redirect to={
                            {
                                pathname:'/',
                                state:{},
                            }
                        }/>
                    }
                }
            }
        />

    );
}

export default ProtectedRoute;