
/**
 * App configuration
 */
class APPConfig {
  /**
   * api url endpoint
   * sample: http://localhost:3000/api
   */
  static API_URL: string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : '';
  static DEFAULT_TIMEZONE: string = process.env.REACT_APP_DEFAULT_TIMEZONE ? process.env.REACT_APP_DEFAULT_TIMEZONE : 'America/New_York';
}

export default APPConfig;